header {
 padding: 80px 0 0 0; 

 @media only screen and (max-width: 1800px) {
  padding: 100px 0 0 0;
 }

 @media only screen and (max-width: 990px) {
  padding: 80px 0 0 0;
 }
}

header>.wrapper {
 padding: 0;
 position: relative;
 z-index: 60;
 width: 90%;
}

header h1 {
 margin: 0;
 background: none;
}

#logo-index {
 display: inline-block;
 margin: 0;
 padding: 0;
 float: left;

 @media only screen and (max-width: 990px) {
  float: none;
  margin: 0 auto 30px;
 }

 a {
  p {
   display: inline-block;
   vertical-align: middle;
   margin: 0;
   text-align: left; 
   font-size: 50px;
   color: #fff;
   line-height: 1;
   font-weight: 800;
   text-transform: uppercase;

   @media only screen and (max-width: 1800px) {
    font-size: 40px;
   }

   @media only screen and (max-width: 990px) {
    display: block;
    text-align: center;
    font-size: 22px;
   }

   span {
    display: block;
    font-size: 40px;
    text-transform: none;

    @media only screen and (max-width: 1800px) {
     font-size: 30px;
    }

    @media only screen and (max-width: 990px) {
     font-size: 20px;
    }
   }
  }

  img#logo {
   display: inline-block;
   vertical-align: middle;
   margin: 0 10px 0 0;

   // @media only screen and (max-width: 1800px) {
   //  max-width: 120px;
   // }

   // @media only screen and (max-width: 1500px) {
   //  max-width: 100px;
   // }

   @media only screen and (max-width: 990px) {
    display: block;
    margin: 0 auto;
   }
  }
 }
}

#trustLink {
 margin: 20px auto 0;
 display: block;
 @include box(84px);

 @media only screen and (min-width: 990px) {
  display: none;
 }
}

// ----------------------------------- SOCIAL DOCK

#social-dock {
 position: absolute;
 right: 6%;
 top: 100px;
 z-index: 99;

 @media only screen and (max-width: 990px) {
  @include posreset;
 }

 li {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  background: none;
  margin: 0;
  position: relative;
  transition: .25s;

  @media only screen and (max-width: 990px) {
   display: inline-block;
   margin: 5px;
  }

  &:last-of-type {
   margin: 0 0 0 10px;

   @media only screen and (max-width: 990px) {
    display: none;
   }
  }

  &:not(:last-of-type) {
   margin: 0 2px 0 0;

   @media only screen and (max-width: 990px) {
    margin: 2px;
   }
  }

  img {
   // @include center;
   display: inline-block;
   margin: 0 auto;
   vertical-align: middle;
  }

  a {
   display: block;
   @include box(100%);
  }

  .tooltip {
   position: absolute;
   left: 50%;
   margin: 0;
   bottom: -10px;
   width: 100px;
   text-align: center;
   transform: translateX(-50%);
   color: #fff;
   font-size: 12px;
   pointer-events: none;
   user-select: none;
   transition: .25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
   opacity: 0;
   z-index: -1;
   font-weight: 800;

   @media only screen and (max-width: 990px) {
    display: none;
   }
  }

  &:hover {
   .tooltip {
    opacity: 1;
    bottom: -30px;
   }
  }
 }
}

// ----------------------------------- GOOGLE TRANSLATE WIDGET

$translate-size: 45px;

.g-tran,
.translate {
 @include box($translate-size);

 img {
  pointer-events: none;
  user-select: none;
  position: relative;
  top: -$translate-size;
 }
}

#google_translate_element {
 opacity: 0;
}

.translate {
 overflow: hidden;
 z-index: 99;
}

.goog-te-gadget .goog-te-combo {
 padding: 10px;
}

// ----------------------------------- SEARCH FORM

a#FS {
 padding: 0px;
 transition: all .3s ease;
 cursor: pointer;
}

/* Search Style */
#search {
 position: fixed;
 top: 0px;
 left: 0px;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.9);
 transition: all 0.5s ease-in-out;
 transform: translate(0px, -100%) scale(0, 0);
 opacity: 0;
 display: none;
}

#search.open {
 transform: translate(0px, 0px) scale(1, 1);
 opacity: 1;
 z-index: 9999999;
 display: block;
}

#search input[type="search"] {
 position: absolute;
 top: 50%;
 left: 0;
 margin-top: -51px;
 width: 60%;
 margin-left: 20%;
 color: rgb(255, 255, 255);
 background: transparent;
 border-left: 0px solid transparent;
 border-right: 0px solid transparent;
 font-size: 40px;
 font-weight: 300;
 text-align: center;
 outline: none;
 padding: 10px;

 @media only screen and (max-width: 990px) {
  width: 70%;
  max-width: 250px;
  background: #fff;
  border-radius: 40px;
  padding: 20px;
  color: #111;
  font-size: 15px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -50px auto 0;
  outline: none;
  box-shadow: 0 0 0 3px #cccccc;
 }
}