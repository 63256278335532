#btns-sec {
  background: $buttons-bg;
  position: relative; 
  padding: 30px 0 100px;
  z-index: 1;

  &:before {
    @include before(100%, 100px);
    background: #fff;
    top: 0;
  }

  &:after {
    @include before(100%, 500px);
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(#fff, 1) 0%, rgba(#fff, 0) 100%);
    z-index: -1;
  }

  .wrapper {
    width: 90%;
  }
}

.btns {
  text-align: center;
  @extend .clearfix;
  position: relative;
  z-index: 999;

  li {
    display: inline-block;
    padding: 0;
    background: none;
    position: relative;
    overflow: hidden;
    transition: .25s;
    max-width: calc(100% / 4 - 20px);
    border-radius: 10px;

    @media only screen and (max-width: 990px) {
      @include box(250px, 300px);
      margin: 5px;
      max-width: unset;
    }

    a {
      @include box(100%);
      display: block;
      transition: .25s;
      border-radius: inherit;
      overflow: hidden;
      position: relative;

      &:before {
        @include before(100%, 50%);
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
        z-index: 101;
        opacity: .8;
        transition: .25s;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .25s;
    }

    span {
      @include center;
      top: 85%;
      color: #fff;
      width: 100%;
      z-index: 102;
      font-size: 1.8vw;
      font-weight: 900;

      @media only screen and (max-width: 990px) {
        font-size: 20px;
      }
    }

    &:hover {
      a {
        &:before {
          height: 100%;
        }
      }

      img {}
    }
  }

  @media only screen and (min-width: 990px) {

    &:hover li:not(:hover) {
      opacity: .3;
    }
  }
}