// the trigger

#trustPopTrigger {

 @media only screen and (max-width: 990px) {
  display: none;
 }

 span {
  display: block;
  text-align: center;
  margin: 0 auto 10px;
  color: #fff;
  width: 100%;
  white-space: nowrap;
  font-size: 10px;
 }
}

// the content

#trustPopDetails {
 position: fixed;
 left: 0;
 bottom: -250px;
 opacity: 0;
 pointer-events: none;
 width: 100%;
 background: rgba(#fff, .9);
 backdrop-filter: blur(5px);
 padding: 50px 5%;
 box-shadow: -10px -10px 50px 0 rgba(0, 0, 0, 0.05);
 transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1);
 z-index: -1;

 @media only screen and (max-width: 990px) {
  display: none;
 }

 .wrapper {
  text-align: center;
 }

 h2 {
  margin: 0 auto 20px;
  font-size: 1.6vw;
  line-height: 1;
  font-weight: bold;
 }

 p {
  font-size: 16px;
  margin: 0 auto 20px;
 }

 .more {
  display: block;
  text-align: center;
  width: 250px;
  height: 50px;
  line-height: 50px;
  // border-radius: 80px;
  color: #fff;
  transition: .25s;
  background: $h1-color;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 auto;
  font-weight: bold;

  &:hover {
   box-shadow: inset 0 0 0 25px rgba(#fff, .2);
  }
 }

 #closer {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 1;
  padding: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #111;

  &:hover {
   color: #555;
  }
 }

 &:hover {
  background: #fff;
 }
}

// out state

#trustPopDetails.pop-me {
 bottom: 0;
 opacity: 1;
 pointer-events: all;
 z-index: 999;
}