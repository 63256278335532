$gradient-top: #28295e;

$welcome-bg: #28295e;
 
$buttons-bg: #eee;

$wall-heading: #c3c3c3;
$wall-bg: #67a6bb; 

$why-choose-text: $welcome-bg;

$colour-strip1: #28295e;
$colour-strip2: #67a6bb;
$colour-strip3: #8c8991;
$colour-strip4: #eee;

$footer-heading: #28295e;

$h1: #28295e;
$h2: #67a6bb;
$h3: #8c8991;
$h4: #28295e;