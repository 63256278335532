// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_2261 #slider { 
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
  position: relative;

  @media only screen and (max-width: 990px) {
    height: 700px;
    min-height: 700px;
  }

  // &:after {
  //   @include before(100%, 500px);
  //   bottom: 0;
  //   left: 0;
  //   background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
  // }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 500px;
  position: relative;
  z-index: 1;
  outline: 1px solid rgba(#fff, .5);
  outline-offset: -20px;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    outline: none;
  }

  @media only screen and (max-width: 990px) {
    height: 450px;
    min-height: 450px;
  }

  &:before {
    @include before(100%, 450px);
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba($gradient-top, 1) 0%, rgba($gradient-top, 0) 100%);
    z-index: -1;
  }
}

.backstretch {
  position: relative;

  img {
    top: 0px !important;
    // animation: scale 25s ease forwards infinite;
  }

  // @keyframes scale {
  //   from {
  //     transform: scale(1);
  //   }

  //   to {
  //     transform: scale(1.25);
  //   }
  // }
}

.body_2261 .backstretch {

  @media only screen and (min-width: 990px) {
    opacity: 0;
    animation: appear 2s ease forwards 1;

    @keyframes appear {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
}