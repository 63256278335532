	footer {
		background: #fff;
		clear: both;
		margin: 0 auto; 
		padding: 20px 0 50px;
		position: relative;
		z-index: 99;

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 20px 0 50px;
		}

		&:before {
			@include before(100%, 20px);
			background-image: linear-gradient(to right, $welcome-bg 0%, rgb(255, 255, 255) 100%);
			bottom: 0;
			border-bottom: 10px solid #fff;
		}

		.wrapper {
			text-align: center;
			font-size: 18px;
			color: #111;
			font-weight: 500;

			@media only screen and (max-width: 990px) {
				width: 100%;
				padding: 0 5%;
				font-size: 15px;
				text-align: center;
			}
		}

		.footer-heading {
			padding: 0;
			margin: 0 auto 20px;
			color: $footer-heading;
			font-size: 50px;
			font-weight: 900;

			@media only screen and (max-width: 1800px) {
				font-size: 40px;
			}

			@media only screen and (max-width: 1500px) {
				font-size: 35px;
			}

			@media only screen and (max-width: 990px) {
				font-size: 30px;
				text-align: center;
				display: block;
				float: none;
			}
		}

		p.address {
			margin: 0;

			strong {
				display: block;
				margin: 0 auto;
				font-size: 20px;

				@media only screen and (max-width: 990px) {
					font-size: 18px;
				}
			}

			a[href^="mailto:"] {
				display: inline-block;
				position: relative;
				color: #111;
				font-weight: 800;

				@media only screen and (max-width: 990px) {
					display: block;
				}

				span {}

				&:before {
					@include before(0, 2px);
					border-radius: 10px;
					background: $footer-heading;
					transition: .25s;
					left: 0;
					bottom: -7px;
				}

				@media only screen and (min-width: 990px) {

					&:hover {
						&:before {
							width: 100%;
						}
					}
				}
			}
		}

		a {
			@media only screen and (max-width: 990px) {
				word-break: break-all;
			}
		}
	}

	#map {
		height: 400px;
		width: 100%;
		overflow: hidden;

		@media only screen and (max-width: 990px) {
			height: 200px;
		}
	}

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//

	.sub-footer {
		background: #fff;
		overflow: hidden;
		@extend .clearfix;
		margin: 70px auto 0;
		padding: 3px 0 0 0;

		@media only screen and (max-width: 990px) {
			height: auto;
			line-height: normal;
			padding: 5px 0;
		}

		p {
			font-size: 13px;
			color: #111;
			margin: 0;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 5px auto;
			}
		}

		#copyright {
			float: left;

			@media only screen and (max-width: 990px) {
				float: none;
			}
		}

		#credit {
			float: right;

			@media only screen and (max-width: 990px) {
				float: none;
			}
		}

		#copyright,
		#credit {

			a {
				color: #111;

				@media only screen and (max-width: 990px) {
					margin: 0;
					display: inline-block;
				}

				&:last-of-type {
					color: #111;

					// GSW logo
					img {
						display: inline-block;
						vertical-align: middle;
						margin: -2px 0 0 5px;
					}
				}
			}
		}
	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.top-btn {
		position: fixed;
		z-index: 99999;
		bottom: 80px;
		right: 50px;
		display: block;
		@include box(45px);
		background: $h1-color;
		box-shadow: 0 0 0 3px #fff, 0 0 0 8px rgba(#fff, .2);
		border-radius: 50%;
		color: #fff;
		font-size: 25px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.show-top-btn {
		opacity: 1;
		transform: scale(1);
	}