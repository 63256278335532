// @include box(200px, 300px); // @include box(200px);
@mixin box ($w, $h: $w) {
	width: $w;
	height: $h;
}

// &:before { @include before(100%, 100%); }
@mixin before ($w: 100%, $h: 100%, $display: block, $pos: absolute, $content: "") {
	content: $content;
	display: $display;
	position: $pos;
	@include box($w, $h);
	pointer-events: none;
	user-select: none;
}

// translate trick
@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// reset
@mixin posreset {
	position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: auto;
	bottom: auto;
	transform: none;
}