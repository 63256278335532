$nav-link-bg-hov: darken($h1-color, 2%);
$nav-dropdown-hov: darken($nav-link-bg-hov, 5%);

nav#navholder {
	position: absolute; 
	top: 50px;
	right: 6%;
	margin: 0; 
	@extend .clearfix;
	width: 990px;
	max-width: 100%;
	z-index: 9999;

	@media only screen and (max-width: 1200px) {
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 35px;
	}

	.wrapper {
		width: 100%;
	}
}

// ----------------------------------- MAIN NAVIGATION

ul.nav {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;

	// Top Level List
	li {
		margin: 0;
		background: none;
		white-space: nowrap;
		padding: 0;
		transition: .25s;

		>a {
			display: block;
			font-size: 18px;
			color: #fff;
			border-radius: inherit;
			position: relative;
			white-space: normal;

			@media only screen and (max-width: 1900px) {
				font-size: 17px;
			}

			@media only screen and (max-width: 1500px) {
				font-size: 16px;
			}

			@media only screen and (max-width: 1350px) {
				font-size: 15px;
			}
		}
	}

	>li:hover>a,
	>li>a:hover {
		color: #fff;
		text-decoration: none;
	}

	// 2nd Level List
	li {
		ul {
			position: absolute;
			left: -999em;
			margin: 0;
			padding: 10px;
			background: none;
			opacity: 0;
			visibility: hidden;
			transition: (all 0.3s ease-in-out);
			background: $nav-link-bg-hov;
			box-shadow: 10px 10px 35px 10px rgba(0, 0, 0, 0.15);
		}

		&:hover ul {
			left: auto;
			margin-left: -20px;
			opacity: 1;
			visibility: visible;
			transition: (all 0.3s ease-in-out);
			z-index: 99;
		}

		&:active ul {
			transition: (opacity 0.1s ease-in-out);
		}
	}

	// 2nd Level List Items
	li li {
		float: none;
		display: block;
		padding: 0;
		height: auto;
		line-height: 35px;
		position: relative;

		a {
			height: auto;
			line-height: 1.1;
			white-space: pre-wrap;
			padding: 9px 12px;
			min-width: 220px;
			float: none;
			top: auto;
			display: block;
			position: static;
			text-align: left;
			text-decoration: none;
			font-size: 14px;
			text-transform: none;
			border-bottom: 1px solid rgba(255, 255, 255, .2);
			border-radius: 0;
			box-shadow: none;
		}

		&:hover,
		&:hover>a,
		a:hover,
		a:focus {
			background: $nav-dropdown-hov;
		}
	}

	// Prepend 'more' arrow to parent list items

	li li a.parent:after {
		margin: -1px 0 0 10px;
		content: url('/i/design/lite/nav-right-arrow-white.png');
		opacity: 1;
		line-height: 1;
	}

	// 3rd Level List
	ul ul {
		z-index: 505;
		background: $nav-link-bg-hov;
	}

	li:hover ul ul {
		left: 100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: (all 0.3s ease-in-out);
		pointer-events: none;
		user-select: none;
		margin: 0 0 0 -5px;
		box-shadow: 10px 10px 35px 10px rgba(0, 0, 0, 0.15);
	}

	ul li:hover ul {
		left: 100%;
		top: -10px;
		opacity: 1;
		visibility: visible;
		transition: (all 0.3s ease-in-out);
		pointer-events: all;
		user-select: all;
	}
}

// ----------------------------------- FLIP DIRECTION OF LAST(N) ITEMS CHILDREN

@media only screen and (min-width: 1200px) {

	ul.nav li:nth-last-of-type(1) ul li ul,
	ul.nav li:nth-last-of-type(1) ul li:hover ul,
	ul.nav li:nth-last-of-type(2) ul li ul,
	ul.nav li:nth-last-of-type(2) ul li:hover ul,
	ul.nav li:nth-last-of-type(3) ul li ul,
	ul.nav li:nth-last-of-type(3) ul li:hover ul,
	ul.nav li:nth-last-of-type(4) ul li ul,
	ul.nav li:nth-last-of-type(4) ul li:hover ul {
		left: -235px;
		width: 240px;
		transition: 0.3s ease-in-out;
	}
}

// ----------------------------------- NAVIGATION DOCKED ON SCROLL

#navholder.fixed {
	position: fixed;
	animation: move-down 1s ease forwards;
	background: rgba(darken($h1-color, 10%), .8);
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
	max-width: unset;
	backdrop-filter: blur(5px);
	z-index: 10001; // is higher than social wall

	.wrapper {
		margin: 0 auto;
		width: 990px;
	}

	ul.nav {
		text-align: center;
		padding: 5px 0;
	}

	@keyframes move-down {
		from {
			top: -50px;
			opacity: 0;
		}

		to {
			top: 0;
			opacity: 1;
		}
	}
}