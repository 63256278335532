// --------------------- RESET MISC.

@import 'core/reset'; 
@import 'core/mixins';
@import 'core/accessibility';

// --------------------- STYLES

@import 'variables';
@import 'c_default';
@import 'c_slider';
@import 'c_header';
@import 'c_footer';
@import 'c_styles'; 
@import 'c_navholder';

// --------------------- OPTIONALS (move from /optionals/ to root to ENGAGE)

@import 'trust-pop';

@import 'photo-buttons';

// @import 'bg-video';

// --------------------- CMS UI Styles

@import 'core/respond';
@import 'core/ui_styles';

// --------------------- MENU

// @import 'megamenu';
@import 'core/meanmenu';
// @import 'meanmenu-ham'; // remember to hide alt.

@import 'core/insidepages-HAP';
@import 'core/cmsstyles';