#welcome-sec {
 padding: 110px 0 130px;
 background: $welcome-bg;
 position: relative;
 
 @media only screen and (max-width: 1800px) {
  padding: 90px 0 110px;
 }

 @media only screen and (max-width: 1500px) {
  padding: 80px 0 90px;
 }

 @media only screen and (max-width: 990px) {
  padding: 70px 0;
 }

 &:before {
  @include before(100%, 20px);
  background-image: linear-gradient(to right, $welcome-bg 0%, rgb(255, 255, 255) 100%);
  top: 0;
  border-bottom: 10px solid #fff;
 }

 &:after {
  @include before(100%, 50px);
  background-image: linear-gradient(to left, $welcome-bg 0%, rgb(255, 255, 255) 100%);
  bottom: 0;
  border-bottom: 40px solid $buttons-bg;

  @media only screen and (max-width: 990px) {
   border-bottom: 10px solid #fff;
   height: 20px;
  }
 }

 .wrapper {
  max-width: 90%;

  @media only screen and (max-width: 990px) {
   max-width: 95%;
  }
 }

 h2 {
  margin: 0 auto 70px;
  font-size: 40px;
  text-align: center;
  color: #fff;
  line-height: 1.2;

  @media only screen and (max-width: 990px) {
   font-size: 30px;
   margin: 0 auto 40px;
  }

  span {
   display: block;
   font-size: 3.5vw;
   font-weight: 900;

   @media only screen and (max-width: 990px) {
    font-size: 20px;
   }
  }
 }

 .cont {

  .text-cont,
  .img-cont {
   display: inline-block;
   vertical-align: top;

   @media only screen and (max-width: 990px) {
    display: block;
   }
  }

  .img-cont {
   @include box(290px, 395px);
   box-shadow: -10px 10px 0 0 #fff;
   background: #fff;

   @media only screen and (max-width: 1100px) {
    box-shadow: 0 0 0 5px #fff;
   }

   @media only screen and (max-width: 990px) {
    margin: 0 auto 50px;
    max-width: 90%;
    border-radius: 20px;
   }

   img {
    @include box(100%);
    object-fit: cover;
    border-radius: 0 0 0 50px;

    @media only screen and (max-width: 990px) {
     border-radius: 20px;
    }
   }
  }

  .text-cont {
   width: calc(100% - 290px - 50px);
   margin: 0 0 0 45px;

   @media only screen and (max-width: 990px) {
    width: auto;
    margin: 0 auto;
   }

   p {
    margin: 0 0 20px 0;
    color: #fff;

    @media only screen and (max-width: 1800px) {
     font-size: 17px;
    }

    @media only screen and (max-width: 1500px) {
     font-size: 16px;
    }

    @media only screen and (max-width: 990px) {
     font-size: 15px;
    }

    &:first-of-type {
     font-weight: 600;
     font-size: 20px;

     @media only screen and (max-width: 1800px) {
      font-size: 19px;
     }

     @media only screen and (max-width: 1500px) {
      font-size: 18px;
     }

     @media only screen and (max-width: 990px) {
      font-size: 17px;
     }
    }

    &:last-of-type {
     margin: 0 0 50px 0;

     @media only screen and (max-width: 990px) {
      margin: 0 0 30px 0;
     }
    }
   }

   .read-more {
    display: block;
    text-align: center;
    width: 230px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    box-shadow: inset 0 0 0 2px #fff;
    transition: .25s;
    float: right;
    font-weight: 800;
    font-size: 15px;

    @media only screen and (max-width: 990px) {
     float: none;
     margin: 0 auto;
    }

    &:hover {
     box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 30px rgba(#fff, .2);
    }
   }
  }
 }
}

#wall-sec {
 padding: 20px 0 100px;
 background: #fff;
 text-align: center;

 h2 {
  margin: 0 auto 50px;
  color: $wall-heading;
  font-size: 50px;
  font-weight: 900;

  @media only screen and (max-width: 1800px) {
   font-size: 40px;
   margin: 0 auto 45px;
  }

  @media only screen and (max-width: 1500px) {
   font-size: 35px;
   margin: 0 auto 40px;
  }

  @media only screen and (max-width: 990px) {
   font-size: 30px;
   margin: 0 auto 35px;
  }
 }

 .wrapper {
  width: 100%;
  background: $wall-bg;
  padding: 100px 5%;
 }
}

#why-choose-sec {
 padding: 50px 0 90px;
 text-align: center;
 background: #fff;
 position: relative;
 z-index: 1;

 .strippers {
  @include center;
  width: 100%;
  z-index: -1;

  span {
   display: block;
   width: 100%;
   height: 60px;
   margin: 0 auto 15px;

   &:nth-of-type(1) {
    background: $colour-strip1;
   }

   &:nth-of-type(2) {
    background: $colour-strip2;
   }

   &:nth-of-type(3) {
    background: $colour-strip3;
   }

   &:nth-of-type(4) {
    background: $colour-strip4;
    margin: 0 auto;
   }
  }
 }

 .wrapper {
  width: 1450px;
  max-width: 90%;
  padding: 80px 5%;
  border-radius: 10px;
  box-shadow: 0 0 0 30px rgba(#fff, .5), -2.121px -2.121px 80px 0px rgba(0, 0, 0, 0.1);
  z-index: 99;
  background: #f3f3f3;
  overflow: hidden;

  @media only screen and (max-width: 990px) {
   padding: 50px 5%;
  }

  &:before {
   @include before(50%, 100%);
   background: url(/i/design/why-choose-bg.jpg) no-repeat left center;
   mix-blend-mode: luminosity;
   opacity: .5;
   top: 0;
   left: 0;
   z-index: 0;
  }

  &:after {
   @include before(100%, 100%);
   top: 0;
   right: 50%;
   background: linear-gradient(to left, rgba(#f3f3f3, 1) 0%, rgba(#f3f3f3, 0) 100%);
   opacity: 1;
   display: block;
   visibility: visible;
  }

  .inner {
   width: 990px;
   max-width: 100%;
   margin: 0 auto;
   position: relative;
   z-index: 99;

   h2 {
    margin: 0 auto 20px;
    font-size: 50px;
    font-weight: 900;
    line-height: 1;

    @media only screen and (max-width: 1800px) {
     font-size: 40px;
    }

    @media only screen and (max-width: 1500px) {
     font-size: 35px;
    }

    @media only screen and (max-width: 990px) {
     font-size: 30px;
     margin: 0 auto 35px;
    }
   }

   p {
    font-size: 20px;
    margin: 0 auto;

    @media only screen and (max-width: 1800px) {
     font-size: 19px;
    }

    @media only screen and (max-width: 1500px) {
     font-size: 18px;
    }

    @media only screen and (max-width: 990px) {
     font-size: 17px;
    }

    &:last-of-type {
     line-height: 1.2;
     font-size: 2.5vw;
     color: $why-choose-text;
     margin: 50px auto 0;
     font-weight: 500;

     @media only screen and (max-width: 990px) {
      font-size: 22px;
      margin: 30px auto 0;
     }
    }
   }
  }
 }
}